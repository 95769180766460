import React from 'react'

const SalesSecurity = () => {
  return (
    <div>
      
    </div>
  )
}

export default SalesSecurity
