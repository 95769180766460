import React from 'react'

const SalesAccount = () => {
  return (
    <div>
    SalesAccount
    </div>
  )
}

export default SalesAccount
