import React from 'react'

const TrialPeriodDiscount = () => {
  return (
    <div>
    TrialPeriodDiscount
    </div>
  )
}

export default TrialPeriodDiscount
