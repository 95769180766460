import React from 'react'

const CustomDiscount = () => {
  return (
    <div>
    CustomDiscount
    </div>
  )
}

export default CustomDiscount
