import axios from "axios";
import { values } from "lodash";

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
}
// ----------------------------------------------------------------------
export const isValidToken = (accessToken) => {
  console.log('accessToken :>> ', accessToken);
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};
// ----------------------------------------------------------------------
export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;
  const currentTime = Date.now();
  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;
  clearTimeout(expiredTimer);
  expiredTimer = setTimeout(() => {
    alert('Token expired');
    sessionStorage.removeItem('accessToken');
    window.location.href = "/";
  }, timeLeft);
};
// ----------------------------------------------------------------------
export const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    sessionStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};


export const customTimeOrhour = [
  { id: "One Miniute", name: "One Miniute" },
  { id: "One Hour", name: "One Hour" },
  { id: "Quarterly", name: "Quarterly" },
  { id: "Custom", name: "Custom" },
]